
    import { Component, Vue } from 'vue-property-decorator';
    import StepProgress from '@/components/StepProgress.vue';
    import CircleProgress from '@/components/CircleProgress.vue';
    import NaviButton from '@/components/NaviButton.vue';
    import CountrySwitcher from '@/components/CountrySwitcher.vue';
    import { useGlobalStore } from '@/store/global';
    import { goBack } from '@/util/misc';
    import { GlobalStep } from '@/enums/GlobalStep';
    import { useSemiLoginStore } from '@/store/semilogin';
    import { useCustomerStore } from '@/store/customer';
    import B2BSwitch from './B2BSwitch.vue';

    @Component({
        components: {
            CountrySwitcher,
            NaviButton,
            StepProgress,
            CircleProgress,
            B2BSwitch,
        },
    })
    export default class Header extends Vue {
        isActive = false;
        openCalendlyModal = false;

        get globalStore() {
            return useGlobalStore();
        }

        get semiLoginStore() {
            return useSemiLoginStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get currentStepNumber() {
            switch (this.globalStore.currentStep) {
                case GlobalStep.CONFIGURATION:
                    return 0;
                case GlobalStep.PRICE_DETAIL:
                    return 1;
                case GlobalStep.CUSTOMER_DATA:
                    return 2;
                case GlobalStep.SUMMARY:
                    return 3;
                case GlobalStep.CONGRATULATIONS:
                    return 4;
                default:
                    return 0;
            }
        }

        get showStepBackNavi() {
            return (
                this.currentRouteName === 'configure' ||
                this.currentRouteName === 'contractdraft' ||
                this.currentRouteName === 'pricedetail' ||
                this.currentRouteName === 'customerform' ||
                this.currentRouteName === 'summary'
            );
        }

        get isSelectorPath() {
            return this.currentRouteName === 'selector';
        }

        get currentRouteName() {
            return this.$route.name;
        }

        goBack() {
            goBack(this.$route);
        }

        homepageBacklink() {
            // If in selector, go back to Homepage, else go back to selector
            if (this.$route.fullPath === '/') {
                if (this.customerStore.customer.is_company) {
                    window.open(
                        `https://firmenwagen.insta-drive.com/${this.globalStore.country}`,
                        '_self',
                    );
                } else {
                    window.open(
                        `//${process.env.VUE_APP_WEBSITE_DOMAIN}/${this.globalStore.country}/`,
                        '_self',
                    );
                }
            } else {
                this.$router.push('/');
            }
        }
    }
